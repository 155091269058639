@charset "UTF-8";
.table-wrapper {
  background: white;
/*  padding: 20px 25px;*/
  margin: 30px 0;
  border-radius: 3px;
  border: 1px solid #dee2e6;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.05);
}

.table-title {
  padding-bottom: 18px;
  background: #435d7d;
  font-size: 22px;
  color: #fff;
  padding: 12px 26px;
  /*margin: -20px -25px 10px;*/
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.no-top-border th {
  border-top: 0px !important;
}

.table {
  border-top: 2px solid #148c9f;
  border-bottom: 2px solid #148c9f;
}

.table tbody {
  cursor: context-menu;
}

.fill-page-width {
    position: absolute;
    width: 95vw; /* the culprit*/
    left: 0px;
    right: 0px;
    margin: auto;
}

.innerCard {
  border-right: 8px solid #148c9f;
  border-left: 8px solid #148c9f;
  border-bottom: 1px solid #1cc8e3;
}

.labels {
  font-family: monospace;
  font-size: 14px;
  width: 100%;
  text-align: right;
}

.inputBox {
  border-right: 1px solid #16a0b6;
  border-left: 4px solid #16a0b6;
  border-radius: 10px 0px 0px 0px;
}

.inputDarkBox {
  border-right: 1px solid #117888;
  border-left: 4px solid #117888;
  border-radius: 10px 0px 0px 0px;
}

.darkBlueColor {
  color: #117888;
}

.turqBackColor {
  background-color: #148c9f;
}

.turqColor {
  color: #148c9f;
}

.darkBlue {
  color: #435d7d;
}

.darkBlueBack {
  background-color: #435d7d;
}

.fontSeventeen {
  font-size: 17px;
}

.fontEighteen {
  font-size: 18px;
}

.fontFourteen {
  font-size: 14px !important;
}

/*buttons*/
.submitBtn {
  border-radius: 10px 10px 10px 10px;
  background-color: #148c9f;
  color: white;
}

.submitBtn:hover {
  color: white;
  background-color: #19b4cc;
}

.cancelBtn {
  border-radius: 10px 10px 10px 10px;
  background-color: #6c757d;
  color: white;
}

.cancelBtn:hover {
  color: white;
  border-color: #adb3b8;
  background-color: #adb3b8;
}

.loginButton {
  border-radius: 10px 10px 10px 10px;
  color: white;
}

.editBtn {
  background-color: white;
  color: #148c9f;
  border: 1px solid #148c9f;
  padding: 0px 0px 10px 0px;
  border-radius: 10px 10px 10px 10px !important;
  width: 165px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: #148c9f;
}

.editBtn:hover {
  background-color: #f2f2f2;
  color: #148c9f;
}

.editBtnShort {
  background-color: white;
  color: #148c9f;
  border: 1px solid #148c9f;
  padding: 0px 0px 10px 0px;
  border-radius: 10px 10px 10px 10px !important;
  width: 140px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: #148c9f;
}

.editBtnShort:hover {
  background-color: #f2f2f2;
  color: #148c9f;
}

.navBarBtn {
  background-color: white;
  color: #148c9f;
  border: 1px solid #148c9f;
/*  padding: 5px 0px 10px 0px; */
  padding: 5px;
  border-radius: 10px 10px 10px 10px !important;
  font-size: 17px;
  font-weight: 600;
  color: #148c9f;
}

.navBarBtn:hover {
  background-color: #f2f2f2;
  color: #148c9f;
}

.btnGroup {
  height: 40px;
}

.btnGroupPadding {
  padding: 10px;
}

/*Radio button- checked*/
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #117888;
  border-color: #117888;
}

.buttonBase {
  background-color: #148c9f;
  border-style: none;
  cursor: pointer;
}

.buttonBase:hover {
  color: white;
  background-color: #19b4cc;
}

.buttonBaseDefault {
  background-color: #6c757d;
  border-style: none;
  cursor: pointer;
}

.buttonBaseDefault:hover {
  color: white;
  background-color: #adb3b8;
}

.commonButton {
  padding: 0px 0px 10px 0px;
  border-radius: 10px 10px 10px 10px !important;
  width: 165px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.commonButton:hover {
  color: white;
  background-color: #19b4cc;
}

.wideCommonButton {
  padding: 0px 0px 10px 0px;
  border-radius: 10px 10px 10px 10px !important;
  width: 195px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.wideCommonButton:hover {
  color: white;
  background-color: #19b4cc;
}

.commonButtonPointLeft {
  padding: 0px 0px 10px 0px;
  border-radius: 10px 0px 0px 10px !important;
  width: 165px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.commonButtonPointRight {
  padding: 0px 0px 10px 0px;
  border-radius: 0px 10px 10px 0px !important;
  width: 165px;
  height: 25px;
  font-size: 17px;
  font-weight: 600;
  color: white;
}

.commonButtonBig {
  padding-top: 6px;
  border-radius: 10px 10px 10px 10px !important;
  height: 38px;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  color: white;
}

.commonButtonBig:hover {
  color: white;
  background-color: #19b4cc;
}

.stick {
  position: fixed;
  z-index: 5;
  right: 0;
}

/*end buttons*/
.page-item.active .page-link {
    background-color: #435d7d;
    border-color: #435d7d;
}

.curvedTopCorner {
  border-radius: 10px 10px 0px 0px;
}

.infoBox {
  border-left: 2px solid #117888;
  border-right: 2px solid #117888;
  background-color: #d7eaea;
}

.dropzoneRadius {
  border-radius: 10px 10px 10px 10px !important;
}

.capBar {
  border-top: 10px solid #435d7d;
  border-radius: 10px 10px 0px 0px;
}

.fontWeight500 {
  font-weight: 500;
}

.noPadLeftRight {
  padding-right: 0px;
  padding-left: 0px;
}

.cardBottom {
  border-bottom: 3px solid #435d7d;
  border-radius: 10px 10px 10px 10px;
}

.highlighted {
  color: black;
  font-weight: bold;
  cursor: context-menu;
  background-color: #bee5eb !important;
}

.noTopBorder {
  border-right: 8px solid #148c9f;
  border-left: 8px solid #148c9f;
  border-bottom: 0px;
  border-top: 0px;
}

.errorBox {
  border: 1px solid red;
  border-top: 3px solid red;
  padding: 20px;
  border-radius: 10px 10px 10px 10px;
}

.floatBox {
  background-color: white;
  border-radius: 10px 0px 0px 10px;
  border-right-style: none;
  border-color: #148c9f;
  border-left: 2px solid #148c9f;
  border-top: 2px solid #148c9f;
  border-bottom: 2px solid #148c9f;
  margin-right: -1rem;
}

.floatOutPosition {
  width: 600px;
  transform: translate(300px);
  transition: transform 1s;
}

.floatInPosition {
  transition: transform 1s;
}

.listGroupPad {
  padding-right: 5px;
  padding-left: 8px;
  padding-top: 8px;
  padding-bottom: 15px;
  background-color: #f0f2f4;
}

.homeLinkGroupNoRightMarg, .homeLinkGroup {
  border: 1px solid #ccc;
  border-radius: 5px 5px 0px 0px !important;
  box-shadow: 4px 4px 3px 0px gray;
  cursor: pointer;
  color: #148c9f;
}

.homeLinkGroup {
  margin-right: 5px !important;
}

.homeLinkGroupNoRightMarg {
  margin-right: 0px !important;
}

.transition {
  -webkit-transition: right 0.3s ease-out;
  /* Chrome 1-25, Safari 3.2+ */
  -moz-transition: right 0.3s ease-out;
  /* Firefox 4-15 */
  -o-transition: right 0.3s ease-out;
  /* Opera 10.50–12.00 */
  transition: right 0.3s ease-out;
  /* Chrome 26, Firefox 16+, IE 10+, Opera 12.10+ */
}

.infoCirle {
  margin-left: 10px;
  margin-right: 30px;
}

.paddingWiderEdge {
  padding: 2px 5px 2px 5px;
}

.modal-header {
  background-color: #148c9f;
  color: white;
  padding: 0.75em 0.75em;
  text-align: center;
}

.bottomLinks a {
  color: #148c9f;
}

.linkList {
  border-bottom: 3px solid #435d7d;
  border-radius: 5px 5px 10px 10px;
  border-top: 3px solid #dee2e6;
}

.borderRightGreen {
  border-right: 2px solid #435d7d;
}

.fontAwesomeArrow {
  font-size: 10px;
  margin-left: 4px;
}

.header-links a {
  font-weight: bold;
  color: black;
  font-size: 20px;
}

.header-links a:hover::after {
  color: black;
  text-decoration: underline;
}

.link-arrow {
  color: darkslategrey;
  font-weight: bold;
}

.listOfLinks {
  overflow-wrap: break-word;
}

.form-control.is-valid {
    background-image: none !important;
    border-color: #ced4da !important;
}

.red-text {
    color: red;
}
