.form-control.is-invalid {
    background-image: none
}

.form-control.is-invalid:focus {
    background-image: none;
    border-color: #ced4da;
    box-shadow: none;
}


.form-control.is-valid {
    border-color: #ced4da;
    background-image: none;
}

.form-control.is-valid:focus {
    background-image: none;
    border-color: #ced4da;
    box-shadow: none;
}

.required {
    color: red;
}

#required-description {
    font-size:14px;
}

@media only screen and (max-width: 768px) {
    .stacked-sometimes
    .stacked-sometimes thead,
    .stacked-sometimes tbody,
    .stacked-sometimes th,
    .stacked-sometimes tr,
    .stacked-sometimes td {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        border: 1px solid #ccc;
    }

    td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
    }

    td:before {
        position: absolute;
        top: 6px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
    }
}