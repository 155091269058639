.unableToProcessLink {
    border: 1px solid #ccc;
    border-radius: 5px 5px 0px 0px !important;
    box-shadow: 3px 3px 2px 0px gray;
    margin-top:8px;
    cursor: pointer;
    color: #148c9f;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .4s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

@media only screen and (max-width: 992px) {
    .fill-page-width {
        all: unset;
    }
}
    
