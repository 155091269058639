.search-glyph {
    margin: auto;
}

@media only screen and (max-width: 767px) {
    .search-glyph {
        font-size: small;
    }
}

@media only screen and (max-width: 991px) {
    .fill-page-width {
        all: unset;
    }
}
