.collapsed > div > .when-opened,
:not(.collapsed) > div > .when-closed {
    display: none;
}


.table-background {
   background-color: #435d7d
}

.hover-test:hover {
    padding: 20px;
    border: 1px dotted black;
    cursor:pointer
}


.form-control[readonly] {
    background-color:white;
}

.form-control:disabled {
    background-color: #e9ecef;
}

#perPageSelect {
    width:55px;
}

/*.table-responsive {
    overflow: scroll !important;
}*/