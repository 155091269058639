 .table td{
    padding:.25em
}

.table  th {
    padding-left: .25em
}

.request-comment {
    font-size: 14px;
    width: 100%;
}

@media only screen and (max-width: 992px) {
    .fill-page-width {
        all: unset;
    }
}
