
@media (min-width: 1px) {

    #banner {
        background-repeat: no-repeat;
        background-color: #32a3d3;
        background-image: url('https://esdorchardstorage.blob.core.windows.net/esdwa/Default/Site/smallEsdbanner.PNG');
        background-size: 180px;
        background-position: 0px 10px;
        height: 97px;
    }

    #title {
        font-size: 10pt;
        text-align: right;
    }
}

@media (min-width: 576px) {
    #title {
        font-size: 12pt;
    }
}

@media (min-width: 768px) {

    #banner {
        background-repeat: no-repeat;
        background-color: #32a3d3;
        background-image: url('https://esdorchardstorage.blob.core.windows.net/esdwa/Default/Site/esdbanner.PNG');
        background-size: 450px;
        background-position: 0px 10px;
        height: 97px; 
    }

    #title {
        font-size: 14pt;
    }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}

.navbar-container {
    background-color: #00446B;
    padding: 0 1rem
}

.navbar-nav #acting span {
    color: forestgreen;
    font-weight: bold
}

.wrap {
    width: 100%;
    min-width: 0;
}

.wrap * {
    white-space: normal;
}