@media only screen and (max-width: 992px) {
    .fill-page-width {
        all: unset;
    }
}

.batch-activity-text {
    font-size: 16px;
    margin-top: 5px
}

.vTable {
    width: auto;
}